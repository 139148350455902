import React from 'react'
import Layout from '../components/layout-v2';
import CloudSolutions from "../components/cloud-solutions";


const Page = () => (

  <Layout title="Managed Cloud">
    <section>

      <br/>
      <CloudSolutions/>
      <br/>
    </section>
  </Layout>
);

export default Page;
